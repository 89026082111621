.CHAT {
    width: 100%;
    height: 100%;
    display: flex;
}

.CHAT_SIDEBAR {
    width: 300px;
    border-right: 2px solid #e1e1e1;
}

.CHAT_SIDEBAR .HEADER {
    height: 60px;
    border-bottom: 2px solid #e1e1e1;
    display: flex;
    align-items: center;
}

.CHAT_SIDEBAR .SEARCH_BOX {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CHAT_SIDEBAR .USERS_LIST {
    height: calc(100% - 130px);
    overflow-y: auto;
    padding-top: 10px;
}


.CHAT_SIDEBAR .USERS_LIST::-webkit-scrollbar {
    display: none;
}

.CHAT_SIDEBAR .USERS_LIST .USER_LIST_ITEAM {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 5px 10px;
    border-radius: 10px;
    align-items: center;
}

.CHAT_SIDEBAR .USERS_LIST .USER_LIST_ITEAM .NOTIFICATION {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #38C4B9;
    bottom: 2px;
    border-radius: 50%;
    right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;


}

.CHAT_SIDEBAR .USERS_LIST .USER_LIST_ITEAM.ACTIVE_USER {
    background-color: #38C4B9;
}

.CHAT_SIDEBAR .USERS_LIST .USER_LIST_ITEAM.ACTIVE_USER,
.CHAT_SIDEBAR .USERS_LIST .USER_LIST_ITEAM.ACTIVE_USER .USER_NAME,
.CHAT_SIDEBAR .USERS_LIST .USER_LIST_ITEAM.ACTIVE_USER .LAST_MESSAGE,
.CHAT_SIDEBAR .USERS_LIST .USER_LIST_ITEAM.ACTIVE_USER .LAST_MESSAGE_TIME {
    color: #fff !important;
}

.CHAT_SIDEBAR .USERS_LIST .USER_LIST_ITEAM p {
    margin: 0;
}

.CHAT_SIDEBAR .USERS_LIST .USER_LIST_ITEAM .USER_NAME {
    font-size: 14px;
    color: black;
    font-weight: 500;
    /* width: 0%; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.CHAT_SIDEBAR .USERS_LIST .USER_LIST_ITEAM .LAST_MESSAGE {
    font-size: 13px;
    color: #8d8585;
    font-weight: 300;
    width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.CHAT_SIDEBAR .USERS_LIST .USER_LIST_ITEAM .LAST_MESSAGE_TIME {
    font-size: 11px;
    color: #8d8585;
    font-weight: 300;
    text-wrap: nowrap;
}

.CHAT_SIDEBAR .HEADER ul {
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.CHAT_SIDEBAR .HEADER ul li button {
    position: relative;
}

.CHAT_SIDEBAR .HEADER ul li button span {
    display: inline-block;
    width: 7px;
    height: 7px;
    position: absolute;
    background-color: #38C4B9;
    border-radius: 50%;
}

.MAIN_CHAT {
    width: calc(100% - 300px);
}

.MAIN_CHAT .HEADER {
    height: 60px;
    border-bottom: 2px solid #e1e1e1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.MAIN_CHAT .HEADER p {
    font-size: 17px;
    color: black;
    font-weight: 500;
}

.MAIN_CHAT .FOOTER {
    height: 60px;
    display: flex;
    justify-content: space-between;
}

.MAIN_CHAT .FOOTER .SEND_ATTCHAMENT {
    position: absolute;
    top: -30px;
    left: 50px;
    background-color: aliceblue;
    width: 50%;
    border-radius: 5px;
    padding: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.MAIN_CHAT .FOOTER .SEND_ATTCHAMENT p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
}

.MAIN_CHAT .CHATAREA {
    height: calc(100% - 120px);
    overflow-y: auto;
}

.MAIN_CHAT .CHATAREA::-webkit-scrollbar {
    display: none;
}

.CHATAREA h4 {
    color: #8d8585;
    font-size: 17px;
    font-weight: 400;
}

.CHATAREA .DATE_SEPARATOR {
    color: #8d8585;
    font-size: 13px;
    font-weight: 400;
}

.CHATAREA .MESSAGE_CONTAINER .SEND {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    flex-wrap: wrap;
    word-break: break-word;
}

.CHATAREA .MESSAGE_CONTAINER .SEND .MESSAGE {
    background-color: #d7f9f9;
    padding: 10px 20px;
    border-radius: 5px 5px 5px 20px;
    position: relative;
}

.CHATAREA .MESSAGE_CONTAINER .SEND .MESSAGE .EDIT_MESSAGE {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.8;
    top: 0;
    left: 0;
    padding: 10px 20px;
    border-radius: 5px 5px 5px 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.CHATAREA .MESSAGE_CONTAINER .SEND .MESSAGE .EDIT_MESSAGE .CROSS_ICONS {
    color: #FFF;
    cursor: pointer;
}

.CHATAREA .MESSAGE_CONTAINER .SEND .MESSAGE .DROUPDOUN_ICON {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 13px !important;
    color: #8d8585;
    opacity: 0;
    cursor: pointer;
    transition: all 0.3s;

}

.CHATAREA .MESSAGE_CONTAINER .SEND .MESSAGE:hover .DROUPDOUN_ICON {
    opacity: 1;
}

.CHATAREA .MESSAGE_CONTAINER .RECEIVE {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    word-break: break-word;
}

.CHATAREA .MESSAGE_CONTAINER .RECEIVE .MESSAGE {
    background-color: #e9e9e9;
    padding: 10px 20px;
    border-radius: 5px 5px 20px 5px;
}

.CHATAREA .MESSAGE_CONTAINER p {
    font-size: 13px;
    color: #8d8585;
    font-weight: 400;
}

.CHATAREA .MESSAGE_CONTAINER .MESSAGE p {
    color: #474545;
    font-weight: 400;
}

.CHATAREA .CHAT_UPLOAD_IMAGE_BOX {
    position: relative;
    width: 250px;
    height: 150px;
}

.CHATAREA .CHAT_UPLOAD_IMAGE_BOX img {
    width: 100%;
    height: 100%;
}

.CHATAREA .DOWNLOAD_FILE {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
}

.CHATAREA .CHAT_UPLOAD_IMAGE_BOX:hover .DOWNLOAD_FILE {
    opacity: 1;
    background: rgba(255, 255, 255, 0.52);
    backdrop-filter: blur(1.6px);
}

.NEW_CONVERSATION_BOX {
    height: 350px;
    overflow-y: auto;
    cursor: pointer;
    padding-top: 10px;
}

.NEW_CONVERSATION_BOX::-webkit-scrollbar {
    display: none;
}

.NEW_CONVERSATION_BOX .NEW_USER {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 10px 10px;
    border-radius: 10px;
}


.GROUP_INFO .DETAILS P {
    font-size: 13px;
    color: black;
    font-weight: 400;
}

.GROUP_INFO .NEW_CONVERSATION_BOX .NEW_USER .MESSAGE {
    font-size: 10px !important;
    color: gray;
    font-weight: 400;
}

@media (min-width: 320px) and (max-width: 770px) {
    .CHAT_SIDEBAR {
        width: 100%;
        border-right: 0px solid #e1e1e1;
    }

}