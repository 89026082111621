.block {
    --sz: 8vmin;
    --animation: 700ms cubic-bezier(0.3, 0.5, 0.4, 0.9) infinite alternate-reverse;
    --hm: 4.5vmin;
    height: var(--sz);
    width: var(--sz);
    background-image: var(--bg);
    border-radius: 50%;
    transform: translateX(var(--tX));
    mix-blend-mode: lighten;
    --tX: 0;
}

.blocks {
    width: 80%;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    position: relative;
    z-index: 50;
}

.orange {
    margin-right: var(--hm);
    animation: attract-orange var(--animation);
    --bg: linear-gradient(-50deg, #38C4B9 0%, #b3e0dd 100%);
}

.blue {
    animation: attract-blue var(--animation);
    margin-left: var(--hm);
    --bg: linear-gradient(50deg, #FFF 0%, #c5c5c5 100%);
}

@keyframes attract-orange {
    to {
        transform: translateX(calc(var(--sz) + calc(var(--hm) / 4)));
    }
}

@keyframes attract-blue {
    to {
        transform: translateX(calc(var(--sz) * -1 - calc(var(--hm) / 4)));
    }
}