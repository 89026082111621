.ACCEPT_INVITION_CONTAINER {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.ACCEPT_INVITION_CONTAINER .CREATE_PIN {
    height: 50vh !important;
    background-color: #fff !important;
    overflow-x: hidden;
    width: 500px;
}

.ACCEPT_INVITION_CONTAINER .CREATE_PIN .FORM {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.LOGIN_CONTAINER {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #ccc; */
}

.LOGIN_SECTION {
    height: 80vh;
    background-color: #fff;
    overflow-x: hidden;
}

.LOGIN_SECTION .INFO_SECTION {
    height: 100%;
    background-image: url("../Assets/Images/desktop-wallpaper-website-backgrounds-login-page.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 0 100px;
}

.LOGIN_SECTION .INFO_SECTION>div {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.LOGIN_SECTION .INFO_SECTION h2 {
    color: #fff;
    margin-bottom: 30px;

}

.LOGIN_SECTION .INFO_SECTION p {
    color: #fff;
    line-height: 20px;
    font-size: 13px;

}

.LOGIN_SECTION .FORM_SECTION {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px;
    position: relative;
}

.LOGIN_SECTION .FORM_SECTION .BACK-BUTTON {
    position: absolute;
    bottom: 2px;
}

.LOGIN_SECTION .FORM_SECTION h3 {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 27px;

}

.LOGIN_SECTION .FORM_SECTION p {
    color: gray;
    font-size: 13px;
}

.LOGIN_SECTION .FORM_SECTION .FORM {
    width: 90%;
    margin-top: 40px;
}

/* .LOGIN_SECTION .FORM_SECTION .FORM .PhoneInputInput {
    border: none;
    border-bottom: 1.98px solid gray;
    width: 100%;
}

.LOGIN_SECTION .FORM_SECTION .FORM .PhoneInputInput:hover {
    border-bottom: 1.98px solid black;
}

.LOGIN_SECTION .FORM_SECTION .FORM .PhoneInputInput:focus-visible {
    outline: none;
} */

.LOGIN_SECTION .FORM_SECTION .FORM .ICON {
    color: #33B0A7 !important;
    font-size: 22px;
}

.LOGIN_SECTION .FORM_SECTION .FORM P {
    color: #33B0A7 !important;
    font-size: 15PX;
    font-weight: 500;
    margin-bottom: 0;
    cursor: pointer;

}

.LOGIN_SECTION .FORM_SECTION .FORM .RESEND-OTP {
    color: gray !important;
    font-size: 13px;
}

.LOGIN_SECTION .FORM_SECTION .FORM .USER-PROFILE {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 200px;
    overflow-y: auto;
}

.LOGIN_SECTION .FORM_SECTION .FORM .USER-PROFILE-CONTAINER {
    width: 150px;
    min-height: 150px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
}

.LOGIN_SECTION .FORM_SECTION .FORM .USER-PROFILE-CONTAINER .IMG {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;

}

.LOGIN_SECTION .FORM_SECTION .FORM .USER-PROFILE-CONTAINER.ACTIVE .IMG {
    border: 2px solid #33B0A7;
}

.LOGIN_SECTION .FORM_SECTION .FORM .USER-PROFILE-CONTAINER .IMG img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.LOGIN_SECTION .FORM_SECTION .FORM .USER-PROFILE-CONTAINER .IMG .ICON {
    position: absolute;
    right: 0px;
    top: 10px;
    display: none;
}

.LOGIN_SECTION .FORM_SECTION .FORM .USER-PROFILE-CONTAINER.ACTIVE .IMG .ICON {
    display: block;
}

.LOGIN_SECTION .FORM_SECTION .FORM .USER-PROFILE-CONTAINER p {
    color: gray !important;
    font-size: 13px;
    width: 100%;
    white-space: nowrap; ;
    overflow: hidden;
    text-overflow: ellipsis;
}

.LOGIN_SECTION .FORM_SECTION .FORM .USER-PROFILE-CONTAINER.ACTIVE p {
    color: #33B0A7 !important;
}


.LOGIN_SECTION .FORM_SECTION .FORM .USERS_LOCATION_LIST {
    width: 100%;
    height: 210px;
    overflow-y: auto;
  }
  .LOGIN_SECTION .FORM_SECTION .FORM .USERS_LOCATION_LIST .LOCATION {
    width: 100%;
    min-height: 80px;
    border-radius: 30px;
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, .15)
  }

  .LOGIN_SECTION .FORM_SECTION .FORM .USERS_LOCATION_LIST .LOCATION.ACTIVE {
    background: #38c4b9;
  }

  .LOGIN_SECTION .FORM_SECTION .FORM .USERS_LOCATION_LIST .LOCATION h6:nth-child(1) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
  .LOGIN_SECTION .FORM_SECTION .FORM .USERS_LOCATION_LIST .LOCATION h6:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #5C6881;
  }
  .LOGIN_SECTION .FORM_SECTION .FORM .USERS_LOCATION_LIST .LOCATION.ACTIVE h6 {
    color: #fff;
  }

  .LOGIN_SECTION .FORM_SECTION .FORM .USERS_LOCATION_LIST .LOCATION ul li {
    width: 100%;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #5C6881;
    font-size: 12px;
  }
  .LOGIN_SECTION .FORM_SECTION .FORM .USERS_LOCATION_LIST .LOCATION.ACTIVE ul li {
    color: #FFF;
  }


/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .LOGIN_SECTION .FORM_SECTION {
        padding: 10px 0px !important;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {}

/* // Large devices (desktops, 992px and up) */
@media (max-width: 998px) {}

/* // X-Large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
    .LOGIN_SECTION .FORM_SECTION {
        padding: 50px;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1601px) and (max-width: 5000px) {
    .common-white-bg-box {
        height: 740px !important;
      }
}
@media (min-width: 1370px) and (max-width: 1600px) {
    .common-white-bg-box {
        height: 640px !important;
      }
}
@media (min-width: 1200px) and (max-width: 1369px) {
    .LOGIN_SECTION .FORM_SECTION {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        position: relative;
    }
    .LOGIN_SECTION .FORM_SECTION .FORM {
        width: 90%;
        margin-top: 12px;
    }
    .common-white-bg-box {
        background: #fff;
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        height: 516px !important;
        position: relative;
        overflow-y: scroll;
        padding: 5px 0px;
      }
      .common-component-box {
        overflow-y: hidden;
      }
}
