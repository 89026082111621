@import url('https://fonts.googleapis.com/css2?family=josefin+Sans:wght@400;500;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Josefin Sans', sans-serif;
}

.credit_card {
    width: 100%;
    height: 220px;
    color: #fff;
    cursor: pointer;
    perspective: 1000px;
}

.credit_card_inner {
    width: 100%;
    height: 100%;
    position: relative;
    transition: all 6ms;
    transform-style: preserve-3d;
}

.front,
.back {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(45deg, #0045c7, #ff2c7d);
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 30px;
    border-radius: 15px;
    overflow: hidden;
    z-index: 1;
    backface-visibility: hidden;
}

.credit_card_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.map-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.3;
    z-index: -1;
}

.card-no {
    font-size: 35px;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.card-holder {
    font-size: 12px;
    margin-top: 40px;
}

.card_holder_name {
    font-size: 12px;
    /* margin-top: 40px; */
}

.card_bar {
    background: #222;
    margin-left: -30px;
    margin-right: -30px;
    height: 60px;
    margin-top: 10px;
}

.card-cvv {
    margin-top: 20px;
}

.card-cvv div {
    flex: 1;
}

.card-cvv img {
    width: 100%;
    display: block;
    line-height: 0;
}

.card-cvv p {
    background: #fff;
    color: #000;
    font-size: 22px;
    padding: 2px 20px;
}

.card-text {
    margin-top: 30px;
    font-size: 14px;
}

.signature {
    margin-top: 30px;
}
.back {
    transform: rotateY(180deg);
}
/* .credit_card:hover .credit_card_inner {
    transform: rotateY(-180deg);
} */


  
  .ADD_CARD_FORM input,
  .StripeElement {
    margin: 0px;
    padding: 10px 14px;
    background-color: #fff !important;
    border: 2px solid #f0f0f0 !important;
    font-weight: 400;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
    width: 100% !important;
  }
  
  .ADD_CARD_FORM input:focus,
  .StripeElement--focus {
    border: 2px solid #5c6bc0 !important;
    box-shadow: none !important;

  }
  
  .StripeElement.IdealBankElement,
  .StripeElement.FpxBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }
  
  .StripeElement.PaymentRequestButton {
    height: 40px;
  }
  