.select_box {
    max-height: 400px;
}

.SelectMultiple .search {
    border: none;
    outline: none;
    padding: 12px 10px;
}

.incon_search {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 25px;
    cursor: pointer;
    color: rgb(96, 96, 155);
}

.country_list {
    border: 1px solid #38C4B9;
    border-radius: 5px;
    cursor: pointer;
}


.select_box {
    /* height: 350px; */
    overflow-y: auto;
}

.select_box::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.select_box::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.05);
}

.select_box::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(to right, #999, #888);
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
}

.select_box::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to right, #888, #777);
}