/* Google Fonts Import Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins';
}

.textcolor {
  color: #5c5b5b !important;
}

.bg-light {
  background-color: #ffff !important;
}

.sidebar {
  position: fixed;
  top: 32px;
  left: 0;
  height: 100%;
  width: 260px;
  background: #fff;
  z-index: 100;
  transition: all 0.5s ease;
}

.header {
  box-shadow: 0px 2px 15px #c3b7b7;
}

.sidebar.close {
  width: 78px;
}

.sidebar .nav-links {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}

.sidebar.close .nav-links {
  overflow: visible !important;
}

.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}

.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}

.sidebar .nav-links .meun_hover:hover {
  background: #fff;
}

.sidebar .nav-links>li:hover .iocn-link i {
  color: #fff;
}

.sidebar .nav-links>li:hover .iocn-link span {
  color: #fff;
}

.sidebar .nav-links>li.isactive {
  background: #38C4B9;

}

.sidebar .nav-links>li:hover {
  background: #38C4B9;
}

.sidebar .nav-links>li.isactive {
  background: #38C4B9;

}

.sidebar .nav-links>li.isactive .iocn-link i.arrow {
  color: #fff !important;

}

.sidebar .nav-links>li.isactive .iocn-link a .link_name {
  color: #fff !important;

}

.sidebar .nav-links>li.isactive .iocn-link a i {
  color: #fff !important;

}

.sidebar.close li:hover a i {
  color: #fff;
}

.sidebar.close li:nth-child(1):hover a i {
  color: #000;
}

.sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar.close .nav-links li .iocn-link {
  display: block
}

.sidebar .nav-links li i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #666666;
  font-size: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg) !important;
}

.sidebar.close .nav-links i.arrow {
  display: none;
}

.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar .nav-links li a .link_name {
  font-size: 15.35px;
  font-weight: 400;
  color: #000;
  transition: all 0.4s ease;
}

.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 20px;
  margin-top: -0px;
  background: #a9e4e0;
  color: #000 !important;
  display: none;
}

.sidebar .nav-links li.showMenu .sub-menu {
  display: block !important;
}

.sidebar .nav-links li .sub-menu a {
  color: rgb(255, 255, 255) !important;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  transition: all 0.3s ease;
}

.sidebar .nav-links li .sub-menu li .red {
  color: #fff !important;
}

.sidebar .nav-links li .sub-menu li a {
  color: rgb(49, 49, 49) !important;
}

.sidebar .nav-links li .sub-menu li i {
  color: #666666 !important;
}

.sidebar .nav-links li:hover i {
  color: #fff !important;
}

.sidebar .nav-links li:hover #login_icon {
  color: #666666 !important;
}

.sidebar .nav-links li:nth-child(1) i {
  color: #000 !important;
}

.sidebar .nav-links li:hover a span {
  color: #fff !important;
}

.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 82%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 10px;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}

.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}

.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}

.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 10px 16px;
  opacity: 0;
  pointer-events: none;
}

.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .nav-links li .sub-menu li:hover a {
  color: #fff !important;
}

.sidebar .nav-links li .sub-menu li:hover i {
  color: #fff !important;
}

.sidebar .profile-details {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  border-top: 1px solid #aaaa;
  padding: 12px 0;
  transition: all 0.5s ease;
  background-color: #fff;
}

.sidebar.close .profile-details {
  background-color: #fff;
}

.sidebar.close .profile-details {
  width: 78px;
}

.sidebar .profile-details .profile-content {
  display: flex;
  align-items: center;
}

.sidebar .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}

.sidebar.close .profile-details img {
  padding: 10px;
}

.sidebar .profile-details .profile_name,
.sidebar .profile-details .job {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job {
  display: none;
}

.sidebar .profile-details .job {
  font-size: 12px;
}

.home-section {
  position: relative;
  min-height: 100vh !important;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
  background-color: #F5F5F5;
}

.sidebar.close~.home-section {
  left: 78px;
  width: calc(100% - 78px);
  height: 100vh !important;
  background-color: #F5F5F5;
}

.home-section .home-content {
  display: inline;
  align-items: center;
  flex-wrap: wrap;
  height: 100% !important;
  padding-top: 68px !important;
}

i.bx.bx-bell {
  font-size: 35px;
  color: #4D4D4D;
}

.bx-log-out:hover {
  color: #000 !important;
}


.profile-details-signup {
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  border-top: 2px solid #aaaa;
  padding: 5px 8px;
  transition: all 0.5s ease;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-details-signup .profile-content {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 2px solid gray;

}

.profile-details-signup .profile-content img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile-details-signup .profile_name p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
  color: gray;
}

.profile-details-signup i {
  font-size: 30px;
  color: gray;
}

/* @media screen and (max-width: 400px) {
  .sidebar {
    width: 240px;
  }

  .sidebar.close {
    width: 78px;
  }

  .sidebar .profile-details {
    width: 240px;
  }

  .sidebar.close .profile-details {
    background: none;
  }

  .sidebar.close .profile-details {
    width: 78px;
  }

  .home-section {
    left: 240px;
    width: calc(100% - 240px);
  }

  .sidebar.close~.home-section {
    left: 78px;
    width: calc(100% - 78px);
  }
} */