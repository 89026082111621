   *,
   *::after,
   *::before {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
   }

   a {
     text-decoration: none;
   }

   ul li {
     list-style: none;
   }

   body {
     font-family: "poppins" !important;
     background-color: #F5F5F5;
   }

   h3 {
     font-size: 24.58px;
   }

   h5 {
     font-size: 17.26px;
     font-weight: 400;
   }

   .form-label {
     font-size: 13px !important;
   }

   .form-check-label {
     font-size: 13px !important;
   }

   h4 {
     font-size: 21.85px;
     color: #38C4B9;
   }

   .cursor {
     cursor: pointer !important;
   }

   .TEXT_ELLIPSIS {
     white-space: nowrap !important;
     text-overflow: ellipsis !important;
     overflow: hidden !important;
   }

   .NoRecordFound {
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
   }

   .horizontal_scroll::-webkit-scrollbar-thumb {
     background: #2a5eab !important;
     height: 5px !important;
     border-radius: 10px !important;
   }

   .horizontal_scroll::-webkit-scrollbar-track {
     background: none !important;
   }

   .horizontal_scroll::-webkit-scrollbar {
     height: 5px !important;
   }



   .common_button_contained {
     background: "#38C4B9" !important;
     padding: "6px 15px 6px 15px" !important
   }

   #hover_tr td {
     color: #7B7B7B !important;
     font-size: 12px;
   }

   .error {
     color: red !important;
     font-size: 12px !important;
   }

   .Star_Mark {
     color: red !important;
   }

   .headerlist {
     display: flex;
     margin-bottom: 0;
   }

   .all_icons_color {
     color: #888686 !important;
     font-size: 13px !important;
     cursor: pointer !important;
   }

   .filter_icons {
     color: rgb(136, 134, 134) !important;
     font-size: 20px !important;
     cursor: pointer !important;
   }

   .all_react_icons_color {
     color: rgb(136, 134, 134) !important;
     font-size: 18px !important;
     cursor: pointer !important;
   }

   .body_header {
     margin-bottom: 15px !important;
   }

   .body_header h2 {
     font-size: 21px;
     margin-bottom: 0px;
   }

   .offcanvas-title {
     font-size: 17px !important;
   }

   .modal-title {
     font-size: 17px !important;
   }

   .modal-body p {
     font-size: 13px !important;
   }

   .tab_list .tab {
     font-size: 13px !important;
   }

   .bootstrap_table_responsive thead tr th,
   .bootstrap_table_responsive thead tr td {
     font-size: 13px !important;
     color: black !important;
     font-weight: 400 !important;
     text-wrap: nowrap !important;
   }

   .bootstrap_table_responsive tbody tr td {
     font-size: 12px !important;
     color: gray !important;
     font-weight: 400 !important;
   }

   .css-1dxqrv5-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
     font-size: 12px !important;
   }

   .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
     font-size: 12px !important;
   }

   .css-1xnbq41-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
     font-size: 12px !important;
   }

   .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
     font-size: 12px !important;
   }

   .percentage_Insights p b {
     font-size: 13px;
   }

   .percentage_Insights p {
     font-size: 12px;
   }

   .css-1ex1afd-MuiTableCell-root {
     white-space: nowrap;
   }

   .css-1ygcj2i-MuiTableCell-root {
     white-space: nowrap;
   }

   .rbc-show-more {
     color: #38C4B9 !important;
   }

   .HEADER_PROFILE {
     font-weight: 500;
     font-size: 13px;
     margin-left: 10px;
     display: flex;
     align-items: center !important;
     gap: 10px;
     cursor: pointer;
   }

   .HEADER_PROFILE p {
     font-size: 12px;
     font-weight: bold !important;
   }

   .HEADER_PROFILE .LOCATION {
     font-size: 12px;
     font-weight: 500 !important;
     color: gray;
   }

   .upload_pdf_view {
     width: 100%;
     border: 2px solid #f0f0f0;
     border-radius: 5px;
     height: 35px;
     padding: 4px 0;
     display: flex;
     justify-content: space-between;
     margin: 0;
     overflow: hidden;
   }

   .upload_pdf_view img {
     height: 22px;
     width: 30px;
   }

   .form_bottom_line {
     display: flex;
     justify-content: flex-start;
     align-items: center;
     margin-bottom: 5px;
   }

   .form_bottom_line p {
     margin-bottom: 0;
     margin-right: 10px;
     font-size: 13px;
     font-weight: 500;
   }

   .form_bottom_line .line {
     background-color: #f0f0f0 !important;

     height: 2px !important;
     width: 40%;
   }

   /* table mui tr heigth */
   .css-1q1u3t4-MuiTableRow-root {
     height: 20px !important;
   }

   .form-select:focus,
   .form-control:focus {
     /* box-shadow: #4067c4 0px 0px 0px 1.2px, #4067c4 0px 0px 0px 1.2px !important; */
     border: 2px solid #5c6bc0 !important;
     box-shadow: none !important;
   }

   .form-select:disabled {
     background-color: #f0f0f0 !important;
   }

   .Offcanvas_style {
     width: 460px !important;
     height: calc(100vh - 66px);
     margin-top: 66px;
   }

   .Appointment_Offcanvas_style {
     height: calc(100vh - 66px);
     margin-top: 66px;
   }

   .custom_pills {
     border: 1px solid #38c4b9;
     border-radius: 6px
   }

   .custom_pills .button {
     color: gray !important;
     border-radius: 4px;
     padding: 10px 2px;
     display: flex;
     justify-content: center;
     cursor: pointer;
     font-size: 13px;
   }

   .custom_pills .button.active {
     background-color: #38C4B9 !important;
     color: white !important;
     border-radius: 4px;
     padding: 10px 2px;
     display: flex;
     justify-content: center;
     cursor: pointer;
   }

   /* .mobile_number_input_box input {
     width: 100%;
     position: relative;
     padding: 6px;
     border: 2px solid #f0f0f0 !important;
     border-radius: 5px;
     font-size: 12px;
   }

   .mobile_number_input_box .PhoneInputCountrySelect[disabled] {
     display: none !important;
   } */

   /* @@@@@@@@@@@@ common class dashboard */
   .common-component-box {
     width: 100%;
     height: 100% !important;
     padding: 90px 3% 2% 3% !important;
     /* overflow-y: hidden; */
   }

   .rmsc .dropdown-container {
     background-color: #fff !important;
     border: 2px solid #f0f0f0 !important;
     font-weight: 400 !important;
     border-radius: 5px !important;
     height: 37px !important;
     font-size: 12px !important;
   }

   .text_box_height {
     min-height: 680px;
   }

   .common-white-bg-box {
     background: #fff;
     width: 100%;
     padding: 10px;
     border-radius: 5px;
     height: 740px !important;
     position: relative;
     overflow-y: scroll;
     padding: 5px 0px;
   }

   .common-white-bg-box::-webkit-scrollbar {
     width: 8px;
     height: 8px;
     display: none;
   }

   .common-white-bg-box::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
     border-radius: 10px;
     background: rgba(0, 0, 0, 0.05);
   }

   .common-white-bg-box::-webkit-scrollbar-thumb {
     border-radius: 10px;
     background: linear-gradient(to right, #999, #888);
     -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
   }

   .common-white-bg-box::-webkit-scrollbar-thumb:hover {
     background: linear-gradient(to right, #888, #777);
   }


   #hover_tr:hover {
     box-shadow: 6px 6px 12px #c5c5c5,
       -6px -6px 12px #ffffff;
   }

   .tab_hover_Effect {
     font-size: 13px !important;
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 100%;
   }

   .tab_hover_Effect:hover {
     box-shadow: 6px 6px 12px #c5c5c5,
       -6px -6px 12px #ffffff;
     cursor: pointer;
   }

   /*  common class phone input */

   .react-tel-input .form-control {
     position: relative;
     font-size: 14px !important;
     letter-spacing: .01rem;
     margin-top: 0 !important;
     margin-bottom: 0 !important;
     padding-left: 48px;
     margin-left: 0;
     background-color: #fff !important;
     border: 1px solid #ced4da !important;
     border-radius: 0.25rem !important;
     line-height: 25px !important;
     height: 37px !important;
     width: 100% !important;
     outline: none;
     color: #212529 !important;
     line-height: 1.5;
     background-clip: padding-box;
     transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
   }

   /* login ,signUp ,create pin, verifyotp style */
   .Credential_page {
     height: 100vh;
     display: flex;
     justify-content: center;
     align-items: center;
     background-image: url(../src/Assets//Images//background.png);
     background-repeat: no-repeat;
     background-position: right;
     background-size: cover;
     position: relative;
   }

   .Credential_page.blur_page {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     filter: blur(3px);
   }

   .Credential_page::before {
     content: "";
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: #38C4B9;
     opacity: 0.2;
     z-index: -10;
   }

   .Credential_center_page {
     border-radius: 2.5rem;
   }

   .Credential_page .login_left_page {
     background-color: #fff;
     border-radius: 2.5rem 0 0 2.5rem;
     min-height: 61vh !important;
   }

   .Credential_page .login_form h1 {
     font-size: 44px;
     font-weight: 600;
     color: #38C4B9;
     font-family: sans-serif;
     margin: 1.5rem 0;
   }

   .Credential_page .login_input_section {
     border-bottom: 2px solid #C0C0C0;
   }

   .Credential_page .login_input_section input {
     border: none;
     outline: none;
     padding: 0.2rem 0;
     font-size: 1rem;
     color: #ACACAC;
     Font-weight: "regular"
   }

   .Credential_page .login_input_section button {
     width: 1.5rem;
     height: 1.5rem;
     border: none;
     background-color: #fff;
     font-size: 1.4rem;
     display: flex;
     justify-content: center;
     align-items: center;
   }

   .Credential_page .forget_btn {
     border: none;
     font-size: 0.8rem;
     background-color: #fff;
   }

   .Credential_page .continue_btn {
     font-size: 1.4rem;
     font-weight: 400;
     padding: 0.4rem 0;
     border-radius: 0.5rem;
     background-color: #38C4B9;
     color: #fff;
     border: none;
   }

   .Credential_page .login_right_page {
     background-color: #fff;
     border-radius: 0 2rem 2rem 0;
   }

   .Credential_page .login_right_page img {
     border-radius: 4rem 2rem 2rem 4rem;
     object-fit: cover;
     width: 100%;
     height: 100%;
     background-color: #D5F2F0;
   }

   .select_profile {
     display: flex;
     justify-content: center;
     align-items: flex-start;
     flex-wrap: wrap;
     gap: 10px;
     width: 80%;
   }

   .select_profile .box {
     width: 130px;
     min-height: 130px;
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: center;
     cursor: pointer;
     position: relative;
   }

   .select_profile .box .multiple_user_image {
     width: 100px;
     height: 100px;
     position: relative;
   }

   .select_profile .box p {
     font-size: 12px;
     margin-bottom: 0;
     margin-top: 5px;
   }

   .select_profile .box .multiple_user_image img {
     width: 100%;
     height: 100%;
     object-fit: cover;
     border-radius: 5px;
   }

   .select_profile .box .multiple_user_image .hover_box {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: black;
     display: flex;
     justify-content: center;
     align-items: center;
   }

   .LOGO {
     position: absolute;
     top: 20px;
     left: 20px;
     width: 70px;
     height: 60px;
   }

   .LOGO img {
     width: 100%;
     height: 100%;
   }

   .BACK_BUTTON {
     position: absolute;
     top: 20px;
     right: 20px;
     width: 70px;
     height: 60px;
   }

   .BACK_BUTTON i:hover {
     color: #fff !important;
   }



   /* verify otp  */
   input[type=number]::-webkit-inner-spin-button,
   input[type=number]::-webkit-outer-spin-button {
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     margin: 0
   }

   /* profile_layout */
   .profile_header {
     width: 100%;
     display: flex;
     align-items: center;
     padding: 0 10px;
     z-index: 900;
     height: 60px;
     background-color: #ffffff;
     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
   }

   .profile_sidebar {
     z-index: 5;
     width: 15%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     word-wrap: break-word;
   }

   .profile_sidebar .nav_items {
     display: flex;
     flex-direction: column;
   }

   .profile_content {
     width: 85%;
     background: #F5F5F5;
     display: flex;
     justify-content: center;
     align-items: center;
   }

   .profile_sidebar .link {
     color: #7B7B7B;
     font-size: 16px;
     border: none;
     background-color: transparent;
     padding: 5px 0;
     text-decoration: none;
     margin-top: 10px;
     width: 100%;
     padding-left: 20px;

   }

   .profile_sidebar .active {
     color: #38C4B9;
     background-color: #DEF3F0;

   }

   .profile_login_section {
     width: 100%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 3px 10px;
     border-top: 2px solid gray;
     border-bottom: 2px solid gray;
   }

   .profile_login_section .top {
     display: flex;
     align-items: center;
   }

   .profile_login_section .img {
     width: 40px;
     height: 40px;
     background-color: #aaaaaa;
     border: 2px solid black;
     border-radius: 50%;
   }

   .profile_login_section p {
     margin: 0;
     margin-left: 5px;
   }

   .profile_login_section .img img {
     width: 100%;
     height: 100%;
     border-radius: 50%;
   }

   .profile_login_section .icons_login {
     font-size: 30px;
   }

   /* user details all css */
   /* user basic details */
   .commonrow {
     background-color: #fff;
     padding: 20px;
     height: 87vh;
     margin-left: 1em;
   }

   .nav-link {
     color: #7B7B7B;
   }

   .cus-link {
     color: #7B7B7B !important;
     margin-bottom: 1em;
     border-radius: 5px !important;
     font-size: 13px !important;
   }

   .cus-link.active {
     background-color: transparent !important;
     border: 1px solid #38C4B9 !important;
     color: #38C4B9 !important;
   }

   .bodrerer {
     height: 150px;
     max-width: 300px;
     text-align: center;
     padding: 9%;
     border-style: dashed;
     border-radius: 15px;
     position: relative;
     display: flex;
     justify-content: center;
     align-items: center;
   }

   .bodrerer p {
     font-size: 13px;
   }

   .bodrerer img {
     width: 100%;
     height: 100%;
     position: absolute;
     top: 0;
     left: 0;
     object-fit: inherit;
     border-radius: 15px;

   }

   .bodrerer input {
     display: none;
   }

   .bodrerer span {
     color: #065BAA;
   }

   label {
     cursor: pointer;
   }

   #imageName {
     color: rgb(0, 0, 0);
   }

   .bodrerer .delete_icon_for_image {
     position: absolute !important;
     bottom: 10px !important;
     right: 50% !important;
     transform: translateX(50%);
     font-size: 25px;
     font-weight: 700;
     cursor: pointer;
     color: gray;
     transition: all 0.1s;
     opacity: 0;
   }

   .bodrerer:hover .delete_icon_for_image {
     bottom: -35px !important;
     opacity: 1;
   }

   .outerBox {
     height: 540px !important;
     display: flex !important;
     justify-content: center !important;
     align-items: center !important;
   }

   .form-select {
     color: #ACACAC;
     background-color: #fff !important;
     border: 2px solid #f0f0f0 !important;
     font-weight: 400;
     font-size: 12px !important;
   }

   .form-control {
     color: #ACACAC;
     background-color: #fff !important;
     border: 2px solid #f0f0f0 !important;
     font-weight: 400;
     font-size: 12px !important;
   }


   .form-control:disabled {
     background-color: #f0f0f0 !important;
   }

   .add_degree_btn button {
     border: none;
     background-color: transparent;
     border-bottom: 1.9px solid rgb(33, 33, 117);
     color: rgb(33, 33, 117);
     margin-right: 50PX;
   }

   /* Specialization input */
   .ReactTags__tags {
     position: relative;

   }

   /* Styles for the input */
   .ReactTags__tagInput {
     width: 100%;
     display: inline-block;
     border-bottom: none;
   }

   .hide_input .ReactTags__selected {
     border: 2px solid #F5F5F5;
     margin-top: 10px;
     height: 80px;
     overflow-y: auto;

   }

   .ReactTags__tagInput input.ReactTags__tagInputField,
   .ReactTags__tagInput input.ReactTags__tagInputField:focus {
     display: block;
     width: 100%;
     padding: 0.375rem 0.75rem;
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     color: #212529;
     background-color: #fff;
     background-clip: padding-box;
     border: 1px solid #ced4da;
     appearance: none;
     border-radius: 0.25rem;
     transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
     outline: none;
   }

   /* Styles for selected tags */
   .ReactTags__selected span.ReactTags__tag {
     border: none;
     background: #DEF3F0;
     color: #38C4B9;
     font-size: 12px;
     display: inline-block;
     padding: 5px 12px;
     margin: 5px;
     border-radius: 15px;

   }

   .ReactTags__remove {
     color: #38C4B9 !important;
     margin-left: 10px;
     cursor: pointer;
   }

   /* Styles for suggestions */
   .ReactTags__suggestions {
     position: absolute;
     width: 100%;
     z-index: 100;
   }

   .ReactTags__suggestions ul {
     list-style-type: none;
     background-color: #fff;
     width: 100%;
     max-height: 250px;
     overflow-y: auto;
     display: flex;
     flex-wrap: wrap;
     border: 1px solid #F5F5F5;
   }

   .ReactTags__suggestions li {
     background-color: #F5F5F5;
     padding: 2px 17px;
     margin: 0;
     margin: 5px;
     border-radius: 20px;
   }

   .ReactTags__suggestions li mark {
     background: none;
     font-weight: 500;
     color: #38C4B9;
   }

   .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
     background: #DEF3F0;
     cursor: pointer;
   }

   .ReactTags__remove {
     border: none;
     cursor: pointer;
     background: none;
     color: white;
     color: #38C4B9 !important;
   }

   /* user business details */
   .actionul {
     list-style: none;
     display: flex;
     padding-inline-start: 0px;
     margin-bottom: 0rem;
   }


   .bgclinic {
     background-color: #DEF3F0;
     padding: 6px 18px;
     display: flex;
     justify-content: space-between;
     align-items: center;
   }

   .bgclinic h5 {
     margin-bottom: 0;
   }

   .bgclinic button {
     border: none;
     outline: none;
     background-color: transparent;

   }

   .bgclinic .addicon {
     font-size: 20px;
     background-color: #38C4B9;
     color: #fff !important;
     border-radius: 50%;
     cursor: pointer;
   }

   .Add_Specialization_button {
     font-size: 13px;
     background-color: #38C4B9;
     color: #fff !important;
     border-radius: 50%;
     cursor: pointer;
     padding: 6px 8px;
   }

   /* !!!!!!!!!!!!!!!!!!!!!!!!  setting !!!!!!!!!!!!!!!!!!!!! */

   .list-style {
     list-style: none;
   }

   .list-style li {
     height: 50px;
     border: 1px solid #38C4B9;
     border-radius: 5px;
     padding: 10px 20px;
     margin-bottom: 1em;
   }

   .form-check-input:checked {
     background-color: #38C4B9 !important;
     border-color: #38C4B9 !important;
   }

   .cus-table i {
     margin-right: 10px;
   }


   .accordion-button {
     border-radius: 10px;
     padding: 15px;
     margin-bottom: 1em;
     border-bottom: 1px solid #38C4B9 !important;
     border-right: 1px solid #38C4B9 !important;
     border-top: 1px solid #38C4B9 !important;
     border-left: 1px solid #38C4B9 !important;
   }

   .accordion-flush .accordion-item .accordion-button {
     border-radius: 5px;
   }

   .accordion-body {
     font-weight: 300;
     font-size: 14px;
     line-height: 21px;
     text-align: justify;
     letter-spacing: 0.1em;
   }

   .accordion-body {
     background: #FFFFFF;
     box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
     border-radius: 0px 0px 5px 5px;
     margin-bottom: 1em;
   }

   .accordion-button:not(.collapsed) {
     color: #000 !important;
   }

   /*!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! profile !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!*/
   .action_profile_btn button {
     padding: 4px 15px;
     border: none;
     border-radius: 5px;
   }

   .action_profile_btn .edit {
     background-color: #38C4B9;
     color: #fff;
   }

   .action_profile_btn .delete {
     background-color: rgb(197, 12, 12);
     color: #fff;
   }

   .profile_view p {
     color: gray;
   }

   .cus-link .nav-link {
     color: #7B7B7B;
     border: 1px solid #7B7B7B !important;
     margin-bottom: 1em;
     border-radius: 5px !important;
   }

   .cus-link .nav-link.active {
     background-color: #38c4b9 !important;
     border: 1px solid #38C4B9 !important;
   }

   .cus-tabs .nav-link {
     color: #7B7B7B;
     border: none;
     font-size: 17.26px;
   }

   .cus-tabs .nav-tabs .nav-item.show .nav-link,
   .nav-tabs .nav-link.active {
     color: #38C4B9 !important;
     border-bottom: 2px solid #38C4B9 !important;
     border-top: 1px solid #38C4B9 !important;
     border-left: 1px solid #38C4B9 !important;
     border-right: 1px solid #38C4B9 !important;

   }

   .viewdetail p {
     font-size: 12px !important;
     color: #8D8B8B;
   }

   .specilizationview {
     display: flex;
     list-style: none;
     padding-inline-start: 0px;
     flex-wrap: wrap;
   }

   .specilizationview li {
     background-color: #DEF3F0;
     padding: 7px 12px;
     margin-bottom: 0.5em;
     border-radius: 15px;
     font-size: 13.64px;
   }

   .edit-icon {
     font-size: 18px;
     color: #313131;
   }

   .cus-border {
     border-right: 1px solid #7B7B7B;
   }

   .bgclinic {
     background-color: #DEF3F0;
     padding: 6px 18px;
   }

   .alreadytext {
     padding: 10px 20px;
   }

   .spec-drop {
     border: 1px solid #aaaa;
     padding: 15px;
     height: 150px;
   }

   /*!!!!!!!!!!!!!!!!!!!!!!!!!  offers  !!!!!!!!!!!!!!!!!!!!!!!!!*/




   .filter {
     font-size: 24px;
     margin-top: 7px;
   }


   .dropdown-item {
     font-size: 12px !important;
     background: none;
     border: none;
     color: inherit;
     font: inherit;
     cursor: pointer;
     text-align: left;
     padding: 0;
     display: inline;
   }

   .dropdown-item:focus,
   .dropdown-item:hover {
     color: #38C4B9 !important;
     background-color: #fff !important;
   }


   .offer-active {
     min-height: 320px;
     position: relative;
     margin-bottom: 30px;
     background-color: #E4E9BE;
     color: #000;
     padding: 25px;
     border-radius: 5px;
   }

   .offer-inactive {
     min-height: 320px;
     position: relative;
     margin-bottom: 30px;
     background-color: #FFE4E4;
     color: #000;
     padding: 25px;
     border-radius: 5px;
   }

   .offer-active .wrap {
     width: 100%;
     height: 188px;
     position: absolute;
     top: -8px;
     left: 8px;
     overflow: hidden;
   }

   .offer-active .wrap:before,
   .offer-active .wrap:after {
     content: "";
     position: absolute;
   }

   .offer-active .wrap:before {
     width: 40px;
     height: 8px;
     right: 100px;
     background: #D43113;
     border-radius: 8px 8px 0px 0px;
   }

   .offer-active .wrap:after {
     width: 8px;
     height: 40px;
     right: 0px;
     top: 100px;
     background: #D43113;
     border-radius: 0px 8px 8px 0px;
   }

   .ribbon-active {
     width: 200px;
     height: 40px;
     font-size: 13px;
     line-height: 40px;
     position: absolute;
     top: 30px;
     color: #fff !important;
     right: -50px;
     z-index: 2;
     overflow: hidden;
     -webkit-transform: rotate(45deg);
     transform: rotate(45deg);
     border: 1px dashed;
     box-shadow: 0 0 0 3px #FA7C65, 0px 21px 5px -18px rgba(0, 0, 0, 0.6);
     background: #FA7C65;
     text-align: center;
   }

   .offer-inactive .wrap {
     width: 100%;
     height: 188px;
     position: absolute;
     top: -8px;
     left: 8px;
     overflow: hidden;

   }

   .offer-inactive .wrap:before,
   .offer-inactive .wrap:after {
     content: "";
     position: absolute;
   }

   .offer-inactive .wrap:before {
     width: 40px;
     height: 8px;
     right: 100px;
     background: #4F4F4F;
     border-radius: 8px 8px 0px 0px;
   }

   .offer-inactive .wrap:after {
     width: 8px;
     height: 40px;
     right: 0px;
     top: 100px;
     background: #4F4F4F;
     border-radius: 0px 8px 8px 0px;
   }

   .ribbon-inactive {
     width: 200px;
     height: 40px;
     font-size: 13px;
     line-height: 40px;
     position: absolute;
     top: 30px;
     color: #fff !important;
     right: -50px;
     z-index: 2;
     overflow: hidden;
     -webkit-transform: rotate(45deg);
     transform: rotate(45deg);
     border: 1px dashed;
     box-shadow: 0 0 0 3px #9F9391, 0px 21px 5px -18px rgba(0, 0, 0, 0.6);
     background: #9F9391;
     text-align: center;

   }

   .Offers .percentage {
     color: rgba(33, 37, 41, 0.75) !important;
     font-size: 12px;

   }

   .Offers .offers_name {
     font-size: 13px;
   }

   .Offers p {
     font-size: 12px;
   }

   .Offers .Audience span {
     color: rgba(33, 37, 41, 0.75) !important;
   }

   .Offers .services span {
     color: rgba(33, 37, 41, 0.75) !important;
   }

   .Slider_offers {
     position: relative;
   }

   .Slider_offers p {
     position: absolute;
     right: 10px;
     bottom: -20px;
     color: black;
     font-weight: 500;
   }




   .ribbons-wrapper p {
     margin-bottom: 5px;
   }


   .cus-sort {
     border: 1px solid #38C4B9;
     padding: 7px 10px;
     border-radius: 5px;
   }

   .cus-sort .form-check-input:checked {
     background-color: #38C4B9;
     border-color: #38C4B9;
   }

   .modalform .form-control {
     color: #8D8B8B;
     background-color: #F5F5F5;
     border: 1px solid #F5F5F5;
     font-weight: 400;
   }


   /* !!!!!!!!!!!!!!!!!!!!!!!!!! waitinglist Start  1!!!!!!!!!!!!!!!!!!!!!*/

   th {
     font-size: 15.64px;
     font-weight: 400;
   }

   td {
     font-size: 13.64px;
     color: #4D4D4D;
     vertical-align: middle;
   }

   .hover1:hover {
     box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
   }

   .modalform .form-control {
     color: #8D8B8B;
     background-color: #F5F5F5;
     border: 1px solid #F5F5F5;
     font-weight: 400;
   }

   .visuallyhidden {
     border: 0;
     clip: rect(0 0 0 0);
     height: auto;
     margin: 0;
     overflow: hidden;
     padding: 0;
     position: absolute;
     width: 1px;
     white-space: nowrap;
   }

   .modal-footer {
     border: none;
   }


   .cus-i i {
     font-size: 35px;
   }

   .actionul i {
     font-size: 18px;
   }

   .table_spase.cus-table,
   .readtable {
     border-collapse: separate !important;
     border-spacing: 0 10px !important;
   }

   /* !!!!!!!!!!!!!!!!!!!!!!!!!! waitinglist End  1!!!!!!!!!!!!!!!!!!!!!*/

   /* !!!!!!!!!!!!!!!!!!!!!!!!! services !!!!!!!!!!!!!!!!!!!!!!!!!! */
   /* !!!!!!!!!!!!!!!!!!!!!!!!!! notification !!!!!!!!!!!!!!!!!!!!!! */

   /* !!!!!!!!!!!!!!!!!!!!!!!!!  Availability start !!!!!!!!!!!!!!!!!!!!!!! */

   /* Chrome, Safari, Edge, Opera */
   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
     -webkit-appearance: display;
     margin: 0;

   }

   .SetAvailability_table> :not(caption)>*>* {
     border: none;
   }

   .SetAvailability_table tr td {
     vertical-align: unset !important;
   }

   .SetAvailability_table .cur_week_box {
     color: #000;
     padding: 6px 15px;
     background-color: #fff;
     border: 1px solid rgb(218, 217, 217);
   }

   /* Firefox */
   .cus-border-availablety {
     border: 1px solid #38C4B9 !important;
     border-radius: 5px !important;
   }

   .cus-border-unavailablety {
     border: 1px solid #38C4B9 !important;
     border-radius: 5px !important;
     width: 100%;
     font-size: 15px;
   }

   .cus-border-availablety1 {
     border: 1px solid #38C4B9 !important;
     padding-top: 5px !important;
   }

   .sundaybg {
     background-color: rgb(255, 255, 255) !important;
     color: rgb(0, 0, 0);
   }

   .bx-plus-circle {
     font-size: 25px;
   }

   .cus-week {
     font-size: 13.64px;
     text-align: center;
     vertical-align: middle;
     font-weight: 500;
     border-radius: 5px;
     margin-top: 2px;
   }

   /* .cus-week1>td {
     background-color: #fff !important;
     font-size: 13.64px;
     text-align: left;
     vertical-align: middle;
     font-weight: 500;
     border-radius: 5px;
   } */
   .cus-week1 {
     font-size: 13px !important;
   }


   .Availability_table .cus-table {
     border-collapse: separate !important;
     border-spacing: 0 15px !important;
   }

   .nav-link:focus,
   .nav-link:hover {
     color: #000000;
   }

   .avail-col2 {
     padding: 10px;
     border-radius: 5px;
     min-height: 300px !important;
     position: relative;
   }

   .avail-col2 ul {
     padding: 0 !important;
     margin: 0;
   }

   .avail-col2 .location {
     font-size: 13px
   }

   .avail-col2 .time_zone {
     font-size: 12px
   }

   .avail-col2 p {
     font-size: 13px;
   }

   .action_btn_availability {
     position: absolute;
     bottom: 10px;
     right: 15px;
   }

   .bgcoloravil {
     border: 1px solid #dee2e6;
     border-radius: 10px;
     background-color: #F5F5F5 !important;
     padding: 16px;
   }

   .rc-time-picker-input {
     padding: 18px 7px !important;
     border: 1px solid #38C4B9 !important;
   }

   .rc-time-picker-input[disabled] {
     color: #666 !important;
     background: none !important;
   }

   /* !!!!!!!!!!!!!!!!!!!!!!Availability End !!!!!!!!!!!!!!!!!!!!!!!!!!! */
   /* !!!!!!!!!!!!!!!!!!!!!!!!!! apointments start !!!!!!!!!!!!!!!!!! */
   .rbc-toolbar button {
     font-size: 13px !important;
     padding: 6px 8px !important;
   }

   .rbc-toolbar button.rbc-active:hover,
   .rbc-toolbar button.rbc-active:focus {
     color: #fff !important;
     background-color: #38C4B9 !important;
     border-color: #38C4B9 !important;
   }

   .rbc-toolbar button:active,
   .rbc-toolbar button.rbc-active {
     background-image: none;
     box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
     background-color: #38C4B9 !important;
     border-color: #38C4B9 !important;
   }

   .dropdown-check-list {
     display: inline-block;
   }

   .dropdown-check-list .anchor {
     position: relative;
     cursor: pointer;
     display: inline-block;
     padding: 5px 10px 5px 10px;
     border: 1px solid #ccc;
   }


   .dropdown-check-list .anchor:active:after {
     right: 8px;
     top: 21%;
   }

   .dropdown-check-list ul.items {
     padding: 0px;
     display: none;
     margin: 0;
     border: 1px solid #ccc;
     border-top: none;
     width: 68%;
   }

   .dropdown-check-list ul.items li {
     list-style: none;
   }

   .dropdown-check-list.visible .anchor {
     color: #000000 !important;
   }

   .dropdown-check-list.visible .items {
     display: block;
   }

   .dropdown-check-list ul.items li:hover {
     background-color: rgb(192, 192, 192);
     color: rgb(0, 0, 0);
   }


   /* !!!!!!!!!!!!!!!!!!!!!!!!!! apointments end !!!!!!!!!!!!!!!!!! */



   /* teams */
   .degree {
     color: #065BAA;
     font-weight: 400;
   }

   .teams_frofile_stepper_btn {
     border: none !important;
     margin-right: 12px;
     background-color: transparent;
     color: #5f5f5f !important;
     padding: 5px;
     font-size: 13px !important;
     font-weight: 500;
   }

   .active_teams_frofile {
     border: none !important;
     color: #38C4B9 !important;
     border-bottom: 2px solid #38C4B9 !important;
   }

   .remove_teams_education_btn {
     border: none;
     padding: 7px 20px;
     border-radius: 5px;
   }

   /*!!!!!!!!!!!!!!!!!!!!!!!! patients!!!!!!!!!!!!!!!!!!!! */
   .commonsearch {
     display: flex;
     flex-wrap: wrap;
     list-style: none;
     padding-inline-start: 0px;
   }

   .commonsearch li {
     margin-right: 1em;
     margin-bottom: 1em;
     border: 1px solid #38C4B9;
     border-radius: 20px;
     padding: 4px 10px;
   }

   .commonsearch li:hover {
     background-color: #DEF3F0;
     color: #38C4B9;
     border: 1px solid #38C4B9;

   }

   .selectMedi {
     background-color: #DEF3F0;
     color: #38C4B9;
     border: 1px solid #38C4B9;
   }

   input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
     -webkit-appearance: display;
     margin: 0;

   }

   .patient_number_input input[type=number] {
     -moz-appearance: textfield;
     width: 70px;
     border: 1px solid #38C4B9;
     background-color: #F5F5F5;
   }

   .med-take {
     list-style: none;
     display: flex;
     padding-inline-start: 0px;
   }

   .med-take li {
     margin-right: 1em;
   }

   .med-take li.active {
     background-color: #38C4B9;
     color: #fff;
     padding: 5px 10px;
     border-radius: 5px;
   }

   input[type="date"]::-webkit-calendar-picker-indicator {
     background: transparent;
     bottom: 0;
     color: red;
     cursor: pointer;
     height: 100%;
     left: 0;
     position: absolute;
     right: 0;
     top: 0;
     width: 100%;
   }

   input[type="month"]::-webkit-calendar-picker-indicator {
     background: transparent;
     bottom: 0;
     color: red;
     cursor: pointer;
     height: 100%;
     left: 0;
     position: absolute;
     right: 0;
     top: 0;
     width: 100%;
   }

   .bgImg {
     background-image: url('./Assets/Images/expire.png');
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     height: 100vh !important;
     width: 100%;
   }

   .Token_expired {
     position: absolute;
     top: 40%;
     left: 20%;
   }

   .tab-btn-color {
     background-color: transparent !important;
     color: #7B7B7B !important;
     border: none;
     padding: 8px 0px;
   }

   .tab-btn-color.active {
     background-color: #38C4B9 !important;
     color: white !important;
     border-radius: 4px;
   }

   /*  smoth scroll */
   .show_list_time_slot {
     overflow-y: auto;
   }

   .show_list_time_slot::-webkit-scrollbar {
     width: 8px;
     height: 8px;
   }

   .show_list_time_slot::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
     border-radius: 10px;
     background: rgba(0, 0, 0, 0.05);
   }

   .show_list_time_slot::-webkit-scrollbar-thumb {
     border-radius: 10px;
     background: linear-gradient(to right, #999, #888);
     -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
   }

   .show_list_time_slot::-webkit-scrollbar-thumb:hover {
     background: linear-gradient(to right, #888, #777);
   }

   /* appointment show hide */
   .appointment_show {
     height: 150px !important;
     transition: all 0.5s;
   }

   .appointment_hide {
     height: 0px !important;
     overflow: hidden !important;
     transition: all 0.5s;
   }

   .offers_Card_avilability {
     border: 1px solid rgb(57 199 188);
     box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
     border-radius: 5px;
     padding: 10px 30px;
   }

   .offers_Card_avilability .name {
     margin-left: 15px;
     margin-bottom: 0;
     font-weight: 500;
     color: #000;
     font-size: large;

   }

   .offers_Card_avilability .type {
     margin-left: 45px;
     margin-bottom: 0;
     color: #8D8B8B;
     font-size: 12px;
     font-weight: 400;
   }

   .offers_Card_avilability .dis {
     margin-left: 45px;
     margin-bottom: 0;
     color: #8D8B8B;
     font-size: 12px;
     font-weight: 400;
   }

   .offers_Card_avilability .code {
     margin-left: 45px;
     margin-bottom: 0;
     display: inline-block;
     border: 1px solid #f0f0f0;
     box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
     border-radius: 5px;
     padding: 2px;
     font-size: 12px;
   }

   .offers_Card_avilability .price {
     margin-left: 45px;
     margin-bottom: 0;
     color: #8D8B8B;
     font-size: 12px;
     font-weight: 400;
   }

   .offers_Card_avilability .discount {
     margin-left: 10px;
     margin-bottom: 0;
     color: #8D8B8B;
     font-size: 15px;
     font-weight: 400;
   }

   .offers_Card_avilability .apply {
     width: 100%;
     display: flex;
     justify-content: space-between;
     margin-top: 5px;
     align-items: center;
   }

   .Appoinment_date_range {
     width: 100%;
     height: 36px;
     color: #ACACAC;
     background-color: #fff !important;
     border: 2px solid #f0f0f0 !important;
     font-weight: 400;
     border-radius: 5px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 0 15px;
     cursor: pointer;
     position: relative;
   }

   .Appoinment_date_range_box {
     position: absolute;
     width: 100%;
     min-height: 70px;
     border: 2px solid black;
     top: 40px;
     left: 0px;
     z-index: 10;
     background-color: #fff !important;
     border: 1px solid #f0f0f0 !important;
     color: #000 !important;
   }

   .Appoinment_date_range input[type="text"] {
     width: 80%;
     border: none;
     outline: none;
     cursor: pointer;
     font-size: 12px !important;
   }

   .Appoinment_date_range input[type="text"]:focus {
     visibility: hidden;
   }

   /* ************ availability start ************** */

   .availability__ ul {
     padding: 0;
     margin-bottom: 0;
   }

   .availability__ ul li {
     list-style: none !important;
     color: #7B7B7B;
     font-size: 12px;
   }

   .availability__box .location:nth-child(1) {
     font-size: 13px !important;
     color: black;
   }

   .availability__box .location:nth-child(2) {
     font-size: 11px !important;
     color: #7B7B7B;
     font-weight: 400;
   }

   .availability__box .time_zone {
     font-size: 12px !important;
     color: #7B7B7B;
   }

   .availability__box .availability_time_day {
     /* height: 70px; */
     /* border-bottom: 1.5px solid gray; */
     margin-bottom: 5px;
   }

   .availability__box .day {
     height: 100%;
     display: flex;
     justify-content: flex-start;
     align-items: center;
     font-size: 13px !important;
   }

   .availability__box .day p {
     margin-bottom: 0;
     color: #333333;
   }

   .availability__box .time {
     height: 100%;
     display: flex;
   }

   .availability__box .time ul {
     width: 100%;
     display: flex;
     justify-content: center;
     flex-direction: column;
     align-items: flex-end;
     margin-bottom: 0;
   }

   .availability__box .time ul li {
     font-size: 12px !important;
     color: gray;
     width: 124px;
   }

   /* ************ availability end ************** */


   /* ************ agenda  start ************** */
   .agenda {
     display: flex;
     justify-content: center;
   }

   .agenda .image {
     height: 80px;
     width: 80px;
     border-radius: 50%;
   }

   .agenda .image img {
     width: 100%;
     height: 100%;
     border-radius: 50%;
     object-fit: cover;
   }

   .Agenda_table table thead tr th {
     font-size: 12px !important;
     color: rgb(12, 12, 12) !important;
     font-weight: 400 !important;
     white-space: nowrap;
   }

   .Agenda_table table tbody tr td {
     color: rgb(110, 110, 110);
     font-size: 12px !important;
     font-weight: 400 !important;
     white-space: nowrap;
   }

   .Agenda_table table tbody tr td .pdf {
     width: 30px;
     height: 30px;
     border-radius: 50%;
     border: 2px solid gray;
     cursor: pointer;
   }

   .Agenda_table table tbody tr td .pdf img {
     width: 100%;
     height: 100%;
     object-fit: cover;
     border-radius: 50%;
   }

   .form_order {
     width: 30px;
     height: 30px;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: #cfcfcf;
     color: #fff;
     cursor: pointer;
   }

   .form_order.active {
     background-color: #35A8A8;
   }

   .form_order.treatment_errors {
     background-color: #f75151 !important;
   }

   .add_remove_button .css-12m9wit-MuiButtonBase-root-MuiFab-root {
     box-shadow: none !important;
   }

   /* ************ agenda  end ************** */


   /* upload document  */
   .bg {
     background-color: white;
   }

   .bg2 {
     border-radius: 0 0 30px 30px;
     background: linear-gradient(180deg, #0A8383 50%, #35A8A8 100%);
   }

   .imgs img {
     height: 80px;
     width: 80px;
     border-radius: 50%;
     border: 3px solid white;
   }

   .text-lig {
     color: white;
     font-size: 13px;
   }

   .appointment_details p {
     font-size: 13px;
   }

   .cover-border {
     border: 1px solid gray;
     border-radius: 10px;
   }

   .bgimg {
     background-image: url("../src//Assets//UploadDocumentImage/LOGO2.png");
     background-repeat: no-repeat;
     background-position: center;
   }

   .bgimg h5 {
     margin: 0;
     font-size: 13px;
   }

   .bordrbottom {
     border-bottom: 1px solid gray;
   }

   .bordrbottom h6 {
     font-size: 13px;
   }

   .bordrbottom p {
     font-size: 12px;
   }

   .icone {
     background-color: #12BABA;
     border-radius: 50%;
     height: 36px;
     width: 36px;
     padding: 5px;
   }

   .p-text {
     color: #6F6F6F;
     font-size: 11.24px !important;
   }

   /* e prescription */
   .tbl-border {
     border: 1px solid rgb(183, 183, 183);
     border-radius: 0px 0px 20px 20px;
   }

   .botm-border {
     border-bottom: 1px solid rgb(170, 170, 170);
   }

   .botm-border p {
     font-size: 13px;
   }

   .rx {
     padding: 10px 70px 10px 40px;
     color: #0f9b9b;
     border-radius: 10px;
   }

   /* insight */
   .percentage_Insights_scroll {
     overflow-y: auto;
     height: 35vh;
     cursor: pointer;
   }

   .percentage_Insights_scroll::-webkit-scrollbar {
     width: 8px;
     height: 8px;
     display: none;
   }

   .percentage_Insights_scroll::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
     border-radius: 10px;
     background: rgba(0, 0, 0, 0.05);
   }

   .percentage_Insights_scroll::-webkit-scrollbar-thumb {
     border-radius: 10px;
     background: linear-gradient(to right, #999, #888);
     -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
   }

   .percentage_Insights_scroll::-webkit-scrollbar-thumb:hover {
     background: linear-gradient(to right, #888, #777);
   }

   .rating_sort_button {
     display: flex;
     justify-content: center;
     padding-left: 0;
     gap: 5px;
   }

   .rating_sort_button li {
     width: 25px;
     height: 25px;
     border: 1.5px solid gray;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 0;
     margin: 0;
     border-radius: 2px;
     color: gray;
   }

   .rating_sort_button li.active {
     width: 25px;
     height: 25px;
     border: 1.5px solid #12BABA;
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 0;
     margin: 0;
     border-radius: 2px;
     background-color: #12BABA;
     color: #fff;
   }

   .feedback_card {
     background-color: #F5F5F5;
     border-radius: 10PX;
     padding: 15px 15px;
   }

   .feedback_card img {
     width: 100%;
     height: 100%;
     border-radius: 10px;
   }

   .feedback_card .title {
     color: #000;
     font-size: 13px;
   }

   .feedback_card .title.active {
     color: #12BABA;
   }

   .feedback_card .discription {
     color: #2F2F2F;
     margin-bottom: 0;
     line-height: 20px;
     font-size: 12px;

   }

   .feedback_card .name {
     color: #000;
     margin-top: 10px;
     margin-bottom: 10px;
     font-size: 12px;
   }

   .feedback_card .date {
     color: #000;
     font-size: 12px;

   }

   .feedback_details {
     background-color: #F5F5F5;
     border-radius: 10px;
     padding: 15px 15px;
     font-size: 12px;
   }

   .feedback_details img {
     width: 100%;
     height: 200px;
     border-radius: 10px;
   }

   .feedback_details .title {
     color: #000;
     font-size: 13px;
   }

   .feedback_details .discription {
     color: #2F2F2F;
     margin-bottom: 0;
     line-height: 20px;
     font-size: 12px;
   }

   .Specialization_list_with_service {
     border: 1.5px solid #12BABA;
     border-radius: 5px;
     box-shadow: 11px 12px 15px -3px rgba(0, 0, 0, 0.1);
     margin-bottom: 15px;
   }

   .Specialization_list_with_service .Specialization_box {
     width: 100%;
     display: flex;
     align-items: center;
     padding: 10px 0;
   }

   .Specialization_list_with_service .services_box {
     display: flex;
     gap: 5px;
     flex-wrap: wrap;
   }

   .translate_login_card {
     transform: translateX(500px);
   }


   .Ticket_Attachment img {
     width: 100% !important;
     height: 100px;
     object-fit: cover !important;
     border-radius: 5px;
   }

   .Ticket_Attachment span {
     position: absolute;
     right: 12px;
     color: black;
     font-size: 18px;
     top: -10px;
     cursor: pointer;
   }

   .Ticket_Attachment video {
     width: 100% !important;
     height: 100px;
     object-fit: cover !important;
     border-radius: 5px;
   }

   .ticket_details h3 {
     font-size: 13px;
     font-weight: 800;
   }

   .ticket_details .replies_box h3 {
     font-size: 13px;
     color: #38C4B9;
     margin-bottom: 0;
     font-weight: 800;
   }

   .ticket_details .replies_box span {
     color: gray;
     font-size: 12px;
   }

   .ticket_details .replies_box span span {
     font-weight: 800;
   }

   .ticket_details p {
     font-size: 12px;
     color: #7B7B7B;
   }

   .ticket_details .replies_box p span p {
     margin-bottom: 0;
   }

   .ticket_details .Attachment {
     border: 2px solid #f0f0f0;
     padding: 12px;
     display: flex;
     gap: 12px;
   }

   .ticket_details .Attachment .img_box {
     width: 80px;
     height: 80px;
   }

   .ticket_details .Attachment .video_box {
     width: 200px;
     height: 80px !important;
   }

   .ticket_details .Attachment .img_box img,
   .ticket_details .Attachment .video_box video {
     width: 100%;
     height: 100%;
     object-fit: cover;
     border-radius: 5px;
   }

   .context_box {
     min-height: 30vh;
     max-height: 33vh;
     overflow-y: auto;
     cursor: pointer;
   }

   .context_box::-webkit-scrollbar,
   .Life_style_scroll::-webkit-scrollbar {
     width: 8px;
     height: 8px;
     display: none;
   }

   .context_box::-webkit-scrollbar-track,
   .Life_style_scroll::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
     border-radius: 10px;
     background: rgba(0, 0, 0, 0.05);
   }

   .context_box::-webkit-scrollbar-thumb,
   .Life_style_scroll::-webkit-scrollbar-thumb {
     border-radius: 10px;
     background: linear-gradient(to right, #999, #888);
     -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
   }

   .context_box::-webkit-scrollbar-thumb:hover,
   .Life_style_scroll::-webkit-scrollbar-thumb:hover {
     background: linear-gradient(to right, #888, #777);
   }

   /* QR code */
   .QR_code_page {
     height: 400px;
   }

   .QR_code_page .name {
     color: #38C4B9;
     font-size: larger;
     font-weight: 700;
   }

   .QR_code_page .name span {
     color: #14413d;
   }

   .QR_code_page .title {
     font-size: 12px;
   }

   .QR_code_page h3 {
     font-weight: 600;
     font-size: 13px;
   }

   .profile_tab button {
     margin-bottom: 10px;
     padding: 9px;
     border-radius: 10px;
     background-color: #fff;
     color: gray;
     border: 1.8px solid gray;
     font-size: small;
   }

   .profile_tab button.active {
     background-color: #38C4B9;
     color: #fff;
     border: 1.8px solid #38C4B9;
   }

   /* NEW */
   .PROVIDER_PROFILE .USER_PROFILE_IMAGE {
     width: 150PX;
     height: 150PX;
     border-radius: 50%;
   }

   .PROVIDER_PROFILE .USER_PROFILE_IMAGE img {
     width: 100%;
     height: 100%;
     border-radius: 50%;
   }

   .PROVIDER_PROFILE .USER_INFO {
     display: flex;
     justify-content: center;
     flex-direction: column;
     margin-left: 30px;
   }

   .PROVIDER_PROFILE .USER_INFO p {
     margin-bottom: 5px;
     color: gray;
     font-size: 13px;
   }

   .PROVIDER_PROFILE .BUSINESS_DETAILS h3 {
     margin-bottom: 0;
     font-size: 18px;
   }

   .PROVIDER_PROFILE .BUSINESS_DETAILS ul {
     padding: 0;
   }

   .PROVIDER_PROFILE .BUSINESS_DETAILS ul li {
     font-size: 13px;
     color: gray;
   }

   .PROVIDER_PROFILE .BUSINESS_DETAILS p {
     font-size: 13px;
     color: gray;
   }

   .PROVIDER_PROFILE .CLINIC_LIST {
     overflow-y: auto;
     cursor: pointer;
   }

   .PROVIDER_PROFILE .CLINIC_LIST::-webkit-scrollbar {
     display: none;

   }

   .APPOINTMENT_FILTER {
     position: absolute;
     width: 100%;
     background-color: red;
     height: 10px;
     bottom: -10px;
     transition: all 0.5s;
     z-index: 5000 !important;
   }

   .APPOINTMENT_FILTER.SHOW {
     /* display: block; */
     opacity: 1;
   }

   .APPOINTMENT_FILTER.HIDE {
     opacity: 0;
   }

   .PATIENT-CARD {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 10px 10px;
     flex-wrap: wrap;
     width: 100%;
   }

   .PATIENT-CARD P:nth-child(1) {
     margin: 0;
     font-size: 13px;
     white-space: nowrap;
     ;
     overflow: hidden;
     text-overflow: ellipsis;
   }

   .PATIENT-CARD P:nth-child(2) {
     margin: 0;
     font-size: 12px;
     color: gray;
     white-space: nowrap;
     ;
     overflow: hidden;
     text-overflow: ellipsis;
   }

   .AVAILABILITY-TIME-OVERFLOW {
     height: 200px;
     overflow-y: auto !important;
     cursor: pointer;
   }

   .AVAILABILITY-TIME-OVERFLOW::-webkit-scrollbar {
     display: none;
   }

   .PATIENT-OUTLET-HIEGHT {
     height: calc(100% - 65px);
   }

   .SETTING-OUTLET-HIEGHT {
     height: calc(100% - 65px);
   }

   .STAFF-OUTLET-HIEGHT {
     height: calc(100% - 65px);
   }

   .TEXT-BOX {
     border: 2px solid #f0f0f0 !important;
     min-height: 34px !important;
     border-radius: 5px;
     font-size: 12px;
     padding: 6px;
     gap: 6px;
     display: flex;
     flex-wrap: wrap;
     font-weight: 400;
   }

   .TEXT-BOX ul {
     padding: 0;
   }

   .TEXT-BOX p {
     margin: 0px !important;
     word-break: break-word !important;
   }

   .NESTED-TAB-HEIGHT {
     min-height: 32px;
   }

   .NESTED-CONTAINER-HEIGHT {
     /* height: calc(100% - 32px); */
     height: calc(100% - 57px);
   }

   .HIDEN-ICON {
     position: absolute;
     top: 50%;
     height: 100%;
     background: transparent;
     transform: translate(-50%, -50%);
     right: 0%;
     width: 26px;
     border-radius: 0px 8px 6px 0px;
     display: flex;
     justify-content: center;
     align-items: center;
     /* margin-top: 3p; */
     /* padding-top: 9px; */
     font-size: 13PX;
     cursor: pointer;

   }

   .FILTER-INSIGHT {
     position: absolute;
     width: 100%;
     min-height: 273px;
     left: 0;
     /* bottom: -273px; */
     right: 0;
     z-index: 5;
     background-color: #fff;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     padding: 10px;
   }

   .FILTER-DASHBOARD {
     position: absolute;
     width: 100%;
     height: 273px;
     left: 0;
     bottom: -273px;
     right: 0;
     z-index: 5;
     background-color: #fff;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     padding: 10px;
   }



   .MERGE_ACCOUNT {
     height: 300px;
     overflow: auto;
   }

   .MERGE_ACCOUNT::-webkit-scrollbar {
     display: none;
   }

   .VACCINATION_FORM_VIEW {
     width: 100%;
   }

   .VACCINATION_FORM_VIEW .HEARDER {
     background-color: #209B9B;
     border-bottom: 10px solid #81D3D3;
     height: 72px;
   }

   .VACCINATION_FORM_VIEW .CONTENT {
     min-height: 530px;
     background-color: #fff;
   }

   .VACCINATION_FORM_VIEW .CONTENT table thead tr th {
     font-size: 13px;
     font-weight: 600;
     color: #414141;
   }

   .VACCINATION_FORM_VIEW .CONTENT table tbody tr td {
     font-size: 12px;
     color: #3B3B47;
   }

   .VACCINATION_FORM_VIEW .FOOTER {
     background-color: #209B9B;
     border-top: 10px solid #81D3D3;
     height: 46px;
   }

   .DATEPIKER_NATIVE_INPUT {
     position: absolute;
     overflow: hidden;
     width: 100%;
     height: 100%;
     right: 0;
     top: 0;
     opacity: 0;
   }

   .DATEPIKER_NATIVE_INPUT::-webkit-calendar-picker-indicator {
     position: absolute;
     right: 0;
     width: 100%;
     height: 100%;
     margin: 0;
     padding: 0;
     opacity: 0;
     cursor: pointer;
   }

   .SWITCH_USERS {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     gap: 20px 50px;
     width: 100%;
     height: 200px;
     overflow-y: auto;
     padding: 0 50px;
   }

   .SWITCH_USERS .USER {
     width: 125px;
     height: 125px;
   }

   .SWITCH_USERS .USER .IMG {
     width: 121px;
     height: 121px;
     border-radius: 50%;
     position: relative;
     border: 2px solid #f1f1f1;

   }

   .SWITCH_USERS .USER.ACTIVE .IMG {
     border: 2px solid #FF6534;
   }

   .SWITCH_USERS .USER .IMG img {
     width: 100%;
     height: 100%;
     border-radius: 50%;
   }

   .SWITCH_USERS .USER .IMG .ICON {
     position: absolute;
     right: 0px;
     top: 10px;
     display: none;
   }

   .SWITCH_USERS .USER.ACTIVE .IMG .ICON {
     display: block;
   }

   .SWITCH_USERS .USER p {
     color: gray !important;
     font-size: 13px;
     width: 100%;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
   }

   .SWITCH_USERS .USER.ACTIVE p {
     color: #FF6534 !important;
   }

   .USERS_LOCATION_LIST {
     width: 100%;
     height: 400px;
     overflow-y: auto;
   }

   /* .USERS_LOCATION_LIST::-webkit-scrollbar {
     display: none;
   } */

   .USERS_LOCATION_LIST .LOCATION {
     width: 100%;
     min-height: 100px;
     border-radius: 30px;
   }

   .USERS_LOCATION_LIST .LOCATION.ACTIVE {
     background: #38c4b9;
   }

   .USERS_LOCATION_LIST .LOCATION h6 {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     font-size: 14px !important;
   }

   .USERS_LOCATION_LIST .LOCATION.ACTIVE h6 {
     color: #fff;
   }

   .USERS_LOCATION_LIST .LOCATION ul li {
     width: 100%;
     color: #5C6881;
     font-size: 12px;
   }

   .USERS_LOCATION_LIST .LOCATION.ACTIVE ul li {
     color: #FFF;
   }

   .MY_PLAN .LEFT h5 {
     font-size: 17px;
   }

   .MY_PLAN .LEFT p {
     font-size: 13px;
     line-height: 20px;
     color: rgb(158, 158, 158);
     font-weight: 300;
   }

   .MY_PLAN .RIGHT h5 {
     font-size: 17px;
   }

   .MY_PLAN .RIGHT h6 {
     font-size: 17px;
   }

   .MY_PLAN .RIGHT span {
     font-size: 13px;
   }

   .MY_PLAN .RIGHT i {
     font-size: 13px;
   }

   .MY_PLAN .RIGHT p {
     font-size: 13px;
   }

   .MANAGE_PLAN {
     overflow-x: hidden;
   }

   .MANAGE_PLAN h5 {
     font-size: 17px !important;
   }

   .MANAGE_PLAN p {
     font-size: 13px;
     color: rgb(158, 158, 158);
   }

   .MANAGE_PLAN span {
     font-size: 13px;
   }

   .MANAGE_PLAN .AVAILABLE_ACTIONS ul {
     display: flex;
     flex-wrap: wrap;
   }

   .MANAGE_PLAN .AVAILABLE_ACTIONS ul li {
     width: 50%;
     margin-bottom: 10px;
     list-style: outside;
     color: rgb(158, 158, 158);
     font-size: 13px;
   }

   .MANAGE_PLAN .AVAILABLE_ACTIONS ul li::marker {
     color: rgb(158, 158, 158);
   }


   .css-uxd1ve-MuiButtonGroup-root .MuiButtonGroup-grouped:not(:last-of-type) {
     border-color: #38c4b9 !important;
   }

   /* Dinesh Css */

   .web_hide {
     display: none !important;
   }

   .no_record_img {
     height: 300px;
     width: 400px;
   }

   .no_record_text {
     color: gray;
     font-size: 17px;
   }

   .shadow_hide {
     box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
   }

   .css-12m9wit-MuiButtonBase-root-MuiFab-root {
     z-index: 100 !important;
   }

   /* Dashboard css start*/
   .text_light_small {
     font-size: 12px;
     color: #828282;
   }

   .appt_font_weight {
     font-weight: 700;
   }

   .numbers_font {
     font-size: 18px;
   }

   .upcoming_appt {
     font-size: 14px;
   }

   .shadow_new {
     box-shadow: 0px 0px 13px 0px rgba(62, 143, 238, 0.10);
     background-color: #fff;
   }

   .dashboard_text_color {
     color: #3B3B47 !important;
   }

   .dashboard_icon {
     border-radius: 50%;
     background: #DFF4F3;
     height: 50px;
     width: 50px;
   }

   .common-white-bg-box_dashboard {
     /* background: #fff; */
     width: 100%;
     /* padding: 10px;
      border-radius: 5px; */
     height: auto !important;
     position: relative;
     /* overflow-y: hidden; */
     padding: 5px 0px;
   }

   .common-component-box_dashboard {
     width: 100%;
     height: 100% !important;
     padding: 90px 1% 1% 1% !important;
     /* overflow-y: hidden; */
   }

   .DASHBOARD_UPCOMING_LIST {
     height: 485px;
     overflow-y: auto;
   }

   .DASHBOARD_UPCOMING_LIST::-webkit-scrollbar {
     display: none;
   }


   /* Dashboard css end*/

   /*  *************************************   media queries   *************************************************** */


   @media (min-width: 768px) and (max-width: 1024px) {
     .font_10 {
       font-size: 10px !important;
     }

     .font_12 {
       font-size: 12px !important;
     }

     .font_14 {
       font-size: 14px !important;
     }

     .font_16 {
       font-size: 16px !important;
     }

     .tab_hide {
       display: none !important;
     }

     .tab_show {
       display: block !important;
     }

     .sidebar.close~.home-section {
       left: 0px !important;
       width: 100% !important
     }

     .sidebar.close~.home-section {
       left: 0px;
       width: 100%;
       height: 100vh !important;
       background-color: #F5F5F5;
     }

     .sidebar .nav-links {
       height: 100%;
       padding: 53px 0 0 0;
       overflow: auto;
       margin-top: -10px;
     }

     .sidebar {
       top: 57.5px !important;
       width: 100% !important;
     }

     .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
       padding: 0px !important;
     }

     .css-1k4qnvv-MuiButtonBase-root-MuiButton-root {
       font-size: 10px !important;
     }

     .no_record_img {
       height: 300px;
       width: 320px;
     }

     .no_record_text {
       color: gray;
       font-size: 12px !important;
     }

     .page_name {
       font-size: 16px !important;
     }

     .common-white-bg-box {
       height: 90% !important;
     }

     .css-rorn0c-MuiTableContainer-root {
       height: 100% !important;
     }

     .rbc-toolbar {
       flex-wrap: nowrap !important;
     }

     .rbc-toolbar button {
       font-size: 10px !important;
     }

     .rbc-toolbar .rbc-toolbar-label {
       font-size: 10px !important;
     }

     .rc-time-picker-clear-icon {
       display: none !important;
     }

     .table>:not(caption)>*>* {
       padding: 0px !important;
     }

     .search_input_width_phone {
       width: 112px !important;
     }

     .search_button_top_padding {
       padding-top: 4px;
     }

     .tab_scroll {
       overflow: scroll;
       scrollbar-width: 2px;
     }

     .life_style_table {
       width: 150% !important;
     }

     .archive_width {
       width: 100px !important;
     }

     .mark_all_btn {
       /* padding: 8px 16px !important; */
       font-size: 13px !important;
     }

     .custom_avtar {
       height: 130px !important;
       width: 130px !important;
     }

     .PROVIDER_PROFILE .USER_INFO {
       display: revert;
       /* justify-content: center;
     flex-direction: column; */
       margin-left: 0px;
     }

     .MANAGE_PLAN .AVAILABLE_ACTIONS ul li {
       width: 100%;
       margin-bottom: 10px;
       list-style: outside;
       color: rgb(158, 158, 158);
       font-size: 13px;
     }

     .dropdown_list {
       z-index: 1000 !important;
       position: absolute;
       inset: 0px 0px auto auto;
       margin: 0px;
       transform: translate(0px, -26px) !important;
     }

     .css-pdct74-MuiTablePagination-selectLabel {
       font-size: 12px !important;
     }

     .css-16c50h-MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select {
       font-size: 12px !important;
     }

     .css-levciy-MuiTablePagination-displayedRows {
       font-size: 12px !important;
     }

     .css-i4bv87-MuiSvgIcon-root {
       font-size: 20px !important;
     }

     .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
       margin-left: 0px !important;
     }

     .css-12m9wit-MuiButtonBase-root-MuiFab-root {
       z-index: 100 !important;
     }

     .shadow_hide {
       box-shadow: none !important;
     }

   }

   @media (min-width: 320px) and (max-width: 576px) {
     .font_10 {
       font-size: 10px !important;
     }

     .font_12 {
       font-size: 12px !important;
     }

     .font_14 {
       font-size: 14px !important;
     }

     .font_16 {
       font-size: 16px !important;
     }

     .phone_hide {
       display: none !important;
     }

     .phone_show {
       display: block !important;
     }

     .sidebar.close~.home-section {
       left: 0px !important;
       width: 100% !important
     }

     .sidebar.close~.home-section {
       left: 0px;
       width: 100%;
       height: 100vh !important;
       background-color: #F5F5F5;
     }

     .sidebar .nav-links {
       height: 100%;
       padding: 53px 0 0 0;
       overflow: auto;
       margin-top: -10px;
     }

     .sidebar {
       top: 57.5px !important;
       width: 100% !important;
     }

     .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
       padding: 0px !important;
     }

     .css-1k4qnvv-MuiButtonBase-root-MuiButton-root {
       font-size: 10px !important;
     }

     .no_record_img {
       height: 300px;
       width: 320px;
     }

     .no_record_text {
       color: gray;
       font-size: 12px !important;
     }

     .page_name {
       font-size: 16px !important;
     }

     .common-white-bg-box {
       height: 95% !important;
     }

     .css-rorn0c-MuiTableContainer-root {
       height: 100% !important;
     }

     .rbc-toolbar {
       flex-wrap: nowrap !important;
     }

     .rbc-toolbar button {
       font-size: 10px !important;
     }

     .rbc-toolbar .rbc-toolbar-label {
       font-size: 10px !important;
     }

     .rc-time-picker-clear-icon {
       display: none !important;
     }

     .table>:not(caption)>*>* {
       padding: 0px !important;
     }

     .search_input_width_phone {
       width: 112px !important;
     }

     .search_button_top_padding {
       padding-top: 4px;
     }

     .tab_scroll {
       overflow: scroll;
       scrollbar-width: 2px;
     }

     .life_style_table {
       width: 150% !important;
     }

     .archive_width {
       width: 100px !important;
     }

     .mark_all_btn {
       /* padding: 8px 16px !important; */
       font-size: 13px !important;
     }

     .custom_avtar {
       height: 100px !important;
       width: 100px !important;
     }

     .PROVIDER_PROFILE .USER_INFO {
       display: revert;
       /* justify-content: center;
      flex-direction: column; */
       margin-left: 0px;
     }

     .MANAGE_PLAN .AVAILABLE_ACTIONS ul li {
       width: 100%;
       margin-bottom: 10px;
       list-style: outside;
       color: rgb(158, 158, 158);
       font-size: 13px;
     }

     .dropdown_list {
       z-index: 1000 !important;
       position: absolute;
       inset: 0px 0px auto auto;
       margin: 0px;
       transform: translate(0px, -26px) !important;
     }

     .css-pdct74-MuiTablePagination-selectLabel {
       font-size: 12px !important;
     }

     .css-16c50h-MuiInputBase-root-MuiTablePagination-select .MuiTablePagination-select {
       font-size: 12px !important;
     }

     .css-levciy-MuiTablePagination-displayedRows {
       font-size: 12px !important;
     }

     .css-i4bv87-MuiSvgIcon-root {
       font-size: 20px !important;
     }

     .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
       margin-left: 0px !important;
     }

     .css-12m9wit-MuiButtonBase-root-MuiFab-root {
       z-index: 100 !important;
     }

     .shadow_hide {
       box-shadow: none !important;
     }

   }

   .FAQ ul li {
     list-style: disc;
     color: #000;
     font-weight: 600;
     letter-spacing: 0.5px;
   }

   .FAQ p {
     font-size: 12px;
     font-weight: 400;
     letter-spacing: 0.5px;
   }

   .FAQ .accordion-flush .accordion-item .accordion-button {
     font-size: 13px !important;
     border: none !important;
     border-top: 1px solid rgb(206, 206, 206) !important;
     border-bottom: 1px solid rgb(206, 206, 206) !important;
   }

   .FAQ .accordion-button:focus {
     box-shadow: none !important;
     background-color: none !important;
   }

   /* custom scroll */
   .CUSTOM_SCROLL_STYLE::-webkit-scrollbar-track {
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
     border-radius: 10px;
     background-color: #F5F5F5;
   }

   .CUSTOM_SCROLL_STYLE::-webkit-scrollbar {
     width: 8px;
     background-color: #F5F5F5;
   }

   .CUSTOM_SCROLL_STYLE::-webkit-scrollbar-thumb {
     border-radius: 10px;
     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
     background-color: #38c4b9;
   }

   .rti--container {
     --rti-bg: #fff;
     --rti-border: #f0f0f0 !important;
     --rti-main: #5c6bc0 !important;
     --rti-radius: 5px !important;
     --rti-s: .4rem !important;
     --rti-tag: #38c4b9 !important;
     --rti-tag-remove: #fff !important;
     --rti-tag-padding: .15rem .25rem;
     align-items: center;
     background: var(--rti-bg);
     border: 1px solid var(--rti-border);
     border-radius: var(--rti-radius);
     display: flex;
     flex-wrap: wrap;
     gap: var(--rti-s);
     line-height: 1.4;
     padding: var(--rti-s);
     color: #fff !important;
   }

   .active_button {
     background-color: #38C4B9 !important;
   }

   .active_button button {
     color: #fff !important;
   }